import { ModuleWithProviders } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export function storeDevToolsConfig(enableDevTools: boolean): ModuleWithProviders<StoreDevtoolsModule>[] {
  return enableDevTools
    ? [
        StoreDevtoolsModule.instrument({
          maxAge: 25, // Retains last 25 states
        }),
      ]
    : [];
}
