import { Injectable } from '@angular/core';
import { CorrectionsFormAuditorRemarksGetForm } from '@corrections/corrections-form/shared/form/auditor-remarks/corrections-form-auditor-remarks-get-form-groups.utils';
import { CorrectionsCommoditiesGetForm } from '@corrections/corrections-form/shared/form/commodities/corrections-form-commodities-get-form-groups.utils';
import { CorrectionsDiscountAmountGetForm } from '@corrections/corrections-form/shared/form/discount-amount/corrections-form-discount-amount-get-form-groups.utils';
import { CorrectionsWeightGetForm } from '@corrections/corrections-form/shared/form/weight/corrections-form-weight-get-form-groups.utils';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { WarningSubmitNegativeAmountEnum } from '@shared/enums/corrections/corrections-form/warning-submit.enum';
import { NegativeChargesValidatorModel } from '@shared/models/corrections/corrections-form/negative-charges-validator.model';
import { ErrorModel } from '@shared/models/shared/error.model';

@Injectable()
export class NegativeChargesValidatorsService {

  constructor() { }

  negativeAmount(props: NegativeChargesValidatorModel): ErrorModel[] {
    const { requestActionType } = props;
    if (requestActionType !== RequestActionTypeCd.submit &&
      requestActionType !== RequestActionTypeCd.approved &&
      requestActionType !== RequestActionTypeCd.reSubmit) return [];

    let isManualRate = this.getIsManualRate(props);
    if (!isManualRate) return [];

    // NOTE: If all the commodities are marked as deleted this validation shouldn't be taken into account because we should only validate a commodity whose amount is zero or empty
    let areAllTheCommMarkedAsDeleted = this.getAreAllTheCommMarkedAsDeleted(props);
    let isThereAtLeastOneCommodity = this.getIsThereAtLeastOneCommodity(props);
    if (areAllTheCommMarkedAsDeleted || !isThereAtLeastOneCommodity) return [];

    let isThereAnyWeightValue = this.getIsThereAnyWeightValue(props);
    let areCommAmountSumGraterThanZero = this.getAreCommAmountSumGraterThanZero(props);
    let isThereAnyDiscountAmountValue = this.getIsThereAnyDiscountAmountValue(props);
    let errors: ErrorModel[] = [];

    if (isThereAnyDiscountAmountValue && !isThereAnyWeightValue && !areCommAmountSumGraterThanZero) {
      const error: ErrorModel = {
        message: WarningSubmitNegativeAmountEnum.message
      };
      errors.push(error);
    }

    return errors;
  }

  private getIsManualRate(props: NegativeChargesValidatorModel): boolean {
    const { form } = props;
    const remarksForm = CorrectionsFormAuditorRemarksGetForm.getForm(form);
    const { manualRateInd } = CorrectionsFormAuditorRemarksGetForm.getFormValues(remarksForm);
    return manualRateInd?.value;
  }

  private getIsThereAnyWeightValue(props: NegativeChargesValidatorModel): boolean {
    const { form } = props;
    const wegithForm = CorrectionsWeightGetForm.getForm(form);
    const wegithControls = CorrectionsWeightGetForm.getFormValues(wegithForm);
    const formWeightControls = CorrectionsWeightGetForm.getFormValueFromSelectedOptionWeight({ wegithForm });

    if (wegithControls?.deleteWeight?.value) return false;

    return !!(formWeightControls?.amount?.value || formWeightControls?.rate?.value || formWeightControls?.quantity?.value);
  }

  private getAreAllTheCommMarkedAsDeleted(props: NegativeChargesValidatorModel): boolean {
    const { form } = props;
    const commoditiesFormArray = CorrectionsCommoditiesGetForm.getForm(form);
    const commoditiesControls = CorrectionsCommoditiesGetForm.getFormGroupFromArray(commoditiesFormArray);

    let areAllTheCommMarkedAsDeleted = true;
    for (const commControls of commoditiesControls) {
      const { delete: deleteCommodity } = CorrectionsCommoditiesGetForm.getFormValues(commControls);
      if (!deleteCommodity.value) {
        areAllTheCommMarkedAsDeleted = false;
        break;
      }
    }

    return areAllTheCommMarkedAsDeleted;
  }

  private getIsThereAtLeastOneCommodity(props: NegativeChargesValidatorModel): boolean {
    const { form } = props;
    const commoditiesFormArray = CorrectionsCommoditiesGetForm.getForm(form);
    return !!commoditiesFormArray && commoditiesFormArray.length > 0
  }

  private getAreCommAmountSumGraterThanZero(props: NegativeChargesValidatorModel): boolean {
    const { form } = props;
    const commoditiesFormArray = CorrectionsCommoditiesGetForm.getForm(form);
    const commoditiesControls = CorrectionsCommoditiesGetForm.getFormGroupFromArray(commoditiesFormArray);

    let areCommAmountSumGraterThanZero = false;
    for (const commControls of commoditiesControls) {
      const { amount, delete: deleteCommodity } = CorrectionsCommoditiesGetForm.getFormValues(commControls);
      if (deleteCommodity.value) continue;
      if (amount?.value && amount?.value > 0) {
        areCommAmountSumGraterThanZero = true;
        break;
      }
    }

    return areCommAmountSumGraterThanZero;
  }

  private getIsThereAnyDiscountAmountValue(props: NegativeChargesValidatorModel): boolean {
    const { form } = props;
    const discountAmountForm = CorrectionsDiscountAmountGetForm.getForm(form);
    const discountAmountControls = CorrectionsDiscountAmountGetForm.getFormValues(discountAmountForm);
    if (discountAmountControls.delete.value) return false;

    return !!(discountAmountControls.amount.value || discountAmountControls.percentage.value);
  }
}
