import { FormGroup } from "@angular/forms";
import { CorrectionsAuditorRemarksFormEnum } from "@shared/enums/corrections/corrections-form/auditor-remarks/corrections-auditor-remarks.enum";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsAuditorRemarksFormModel } from "@shared/models/corrections/corrections-form/auditor-remarks/corrections-auditor-remarks.model";


export abstract class CorrectionsFormAuditorRemarksGetForm {
    static getForm = (form: FormGroup): FormGroup => {
        return form.controls[CorrectionsFormEnum.auditorRemarks] as FormGroup;
    }

    static getFormValues = (form: FormGroup): CorrectionsAuditorRemarksFormModel => {
        const authorityRemarks = form.controls[CorrectionsAuditorRemarksFormEnum.authorityRemarks];
        const auditorComments = form.controls[CorrectionsAuditorRemarksFormEnum.auditorComments];
        const doNotSetInvoiceAuditor = form.controls[CorrectionsAuditorRemarksFormEnum.doNotSetInvoiceAuditor];
        const manualRateInd = form.controls[CorrectionsAuditorRemarksFormEnum.manualRateInd];

        return {
            authorityRemarks,
            auditorComments,
            doNotSetInvoiceAuditor,
            manualRateInd
        };
    }
}
