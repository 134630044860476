import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { CorrectionsBillChargesModel } from '@shared/models/corrections/bill-payment/corrections-bill-charges.model';
import { ListArPaymentDetailsByProResp, ListArPaymentDetailsByShipmentPath } from '@xpo-ltl-2.0/sdk-accountsreceivable';
import { take } from 'rxjs/operators';

@Injectable()
export class CorrectionsAccountsReceivableMapperService {
  constructor(private correctionsHandler: CorrectionsHandler) {}

  getRequest(): ListArPaymentDetailsByShipmentPath {
    let shipmentInstId: number | undefined;
    this.correctionsHandler.getShipmentInstId$.pipe(take(1)).subscribe(
      resp => shipmentInstId = resp
    );
    const queryParams: ListArPaymentDetailsByShipmentPath = {
      shipmentInstId: shipmentInstId as number
    };
    return queryParams;
  }

  getBillChargesFromApi(listArPaymentDetailsByProResp: ListArPaymentDetailsByProResp, ctsRev: number, charges: number): CorrectionsBillChargesModel {
    const correctionsBillCharges = new CorrectionsBillChargesModel();
    correctionsBillCharges.adjustments = 0;
    correctionsBillCharges.balanceDue = 0;
    correctionsBillCharges.payments = 0;
    correctionsBillCharges.paymentDetails = listArPaymentDetailsByProResp;
    correctionsBillCharges.charges = charges;
    correctionsBillCharges.ctsRev = ctsRev;

    listArPaymentDetailsByProResp?.arPaymentDetails.reduce((correctionsBillCharges, arPaymentDetail) => {
      correctionsBillCharges.balanceDue += arPaymentDetail.balanceDueAmt || 0;
      correctionsBillCharges.adjustments += arPaymentDetail.totalAdjustmentAmt || 0;
      if (arPaymentDetail.totalPaymentAmt > 0) {
        correctionsBillCharges.payments += arPaymentDetail.totalPaymentAmt;
      }
      return correctionsBillCharges;
    }, correctionsBillCharges);

    return correctionsBillCharges;
  }
}
