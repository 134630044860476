export class ShipmentDetailRespModel {
  billToAgreementId: number;
  shipperAgreementId: number;
  consigneeAgreementId: number;
  billToRulesetNbr: number;
  shipperRulesetNbr: number;
  consigneeRulesetNbr: number;
  pickupDate: string;
  rulesetOwner: string;
  updateByPgmId: string;
}

export enum ShipperRulestEnum {
  shipper = 'S',
  consignee = 'C',
  billTo = 'B'
};
