import { RequestFormMarkedAction } from '@shared/enums/common-data/request-form.enum';
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsWeightFormTypeEnum } from "@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum";
import { CommodityClassCd, CommodityPackageCd, DeliveryQualifierCd, MatchedPartyTypeCd, MiscLineItemCd, SupplementalReference } from "@xpo-ltl/sdk-common";
import { ChargeToCd } from "@xpo-ltl/sdk-common";
import { ShipmentCreditStatusCd } from "@xpo-ltl/sdk-common";
import { EmailModel } from "../shared/email.model";
import { CorrectionsMiscellaneousModel } from "./corrections-form/miscellaneous/corrections-miscellaneous.model";
import { CorrectionsReasonModel } from "./corrections-form/reason/corrections-reason.model";
import { RequestStatusEnum } from '@shared/enums/corrections/corrections-form/request-info/corrections-request-info-form.enum';
import { BillClassEnum } from '@shared/enums/common-data/bill-class.enum';

export class CorrectionsGeneralInfoModel {
  deliveryDate: number | undefined;
  deliverySic: string;
  deliveryStatus: DeliveryQualifierCd;
  expectedDeliveryDate: string;
  freezableInd: boolean;
  guaranteedInd: boolean;
  linealFeet: number;
  motorizedPieces: number;
  palletCount: number;
  rated: boolean;
};

export class CorrectionsBillStatusModel {
  billClass: BillClassEnum;
  closedRequestsNbr: number;
  lastCorrectionDate: number | undefined;
  oracleStatus: string;
  originTerminalSic: string;
  destinationTerminalSic: string;
  chargeCode: ChargeToCd;
  pendingRequestAvailable: number;
  pickupDate: string;
  proNbr: string;
}

export class CorrectionsRequestInformationModel {
  auditorName: string;
  correctionQueue: string;
  categoryReason?: string;
  deliveryDate: number | undefined;
  expectedDelivery: string;
  isDriverWaiting: boolean;
  isFinalDeliveryStatus: boolean;
  pickupDate: string;
  requesterComments: string
  requestDateTime: number | undefined;
  requester: string;
  requesterUserId: string;
  requestStatus?: RequestStatusEnum;
  appliedDate?: number;
  autoRated?: boolean;
  ccEmailTo?: EmailModel[];
  requestInvoiceInd?: boolean;
  reviewQueue?: string;
}

export class CorrectionsExtraInformationModel {
  auditorComments: string;
}

export class CorrectionsInformationRequestModel {
  informationRequestInd: boolean;
}

export class CorrectionsCustomerRespModel {
  address: string;
  city: string;
  chrgsResp?: MatchedPartyTypeCd;
  country: string;
  customerNumber: number;
  madCode: string;
  name1: string;
  name2: string;
  state: string;
  zip: string;
  zipRest: string;
}

export class CorrectionsShipperRespModel extends CorrectionsCustomerRespModel {
  creditStatus: ShipmentCreditStatusCd | undefined;
}
export class CorrectionsConsigneeRespModel extends CorrectionsCustomerRespModel {
  creditStatus: ShipmentCreditStatusCd | undefined;
}
export class CorrectionsBilltoRespModel extends CorrectionsCustomerRespModel {
  chrgsResp?: MatchedPartyTypeCd;
  delete?: boolean;
}

export class CorrectionsCreditStatusRespModel {
  creditStatus: string;
}

export class CustomerLocationRespModel {
  customerMadCodeLocation: string;
}

export class CorrectionsChargeCodeRespModel {
  chargeCode: ChargeToCd;
  ppdText: string;
  ppdAmount: number;
  ppdSequenceNbr: string;
  colText: string;
  colAmount: number;
  colSequenceNbr: string;
}

export class CorrectionsAdvanceScacRespModel {
  scacCode: string;
  date: string;
  proNumber: string;
  advanceRevenue: number;
  deleteAdvanceScac?: boolean | undefined;
}

export class CorrectionsBeyondScacRespModel {
  scacCode: string;
  beyondRevenue: number;
  fromTerminalSic: string;
  deleteBeyondScac?: boolean | undefined;
}

export class CorrectionsTotalRevenueRespModel {
  ctsRevenue: number;
  totalRevenue: number;
  totalWeight: number;
}

export class CorrectionsCustomInBondRespModel {
  bondNumber: string;
  city: string;
  state: string;
}

export class CorrectionsSicRespModel {
  origin: string;
  destination: string;
}

export class CorrectionsGuaranteedRespModel {
  guaranteedInd: boolean;
}

export class CorrectionsMeasuresRespModel {
  deleteMotorizedPieces?: boolean;
  deleteTotalPalletCount?: boolean;
  deleteLinealFeet?: boolean;
  motorizedPieces: number;
  palletCount: number;
  linealFeet: number;
}

export class CorrectionsShippingRemarksRespModel {
  deleteInd: boolean | undefined;
  remarks: string;
}

export class CorrectionsCodAmountRespModel {
  amount: number;
  deleteInd: boolean;
  description: string;
  sequenceNbr: string;
}

export class CorrectionsCommoditiesRespModel {
  id: string;
  sequenceNbr: string;
  amount: number;
  class: CommodityClassCd;
  description: string;
  hazmat: boolean;
  nmfc: string;
  pieces: number;
  pkg: CommodityPackageCd;
  weight: number;
  rate: number;
  delete?: boolean;
  markedAction?: RequestFormMarkedAction;
  minimumChargeInd: boolean;
}

class CorrectionsAsDeficitWeightRespModel {
  amount: number;
  description: string;
  quantity: number;
  rate: number;
  type: MiscLineItemCd;
  sequenceNbr: string;
  minimumChargeInd: boolean;
}

export class CorrectionsDeficitWeightRespModel extends CorrectionsAsDeficitWeightRespModel { }
export class CorrectionsAsWeightRespModel extends CorrectionsAsDeficitWeightRespModel { }

export class CorrectionsWeightLineRespModel {
  deleteInd: boolean;
  typeWeightLine?: CorrectionsWeightFormTypeEnum;
  deficitWeight: CorrectionsDeficitWeightRespModel | undefined;
  asWeight: CorrectionsDeficitWeightRespModel | undefined;
}

export class CorrectionsTariffRespModel {
  code: string;
  effectiveDate: string;
}

export class CorrectionsAmcAmountRespModel {
  amount: number;
}

export class CorrectionsCancelChargesRespModel {
  cancelChargesInd: boolean;
}

export class CorrectionsDiscountAmountRespModel {
  percentage: number;
  description: string;
  amount: number;
  sequenceNbr?: string;
  deleteInd?: boolean;
}

export class CorrectionsAccessorialRespModel {
  id: string;
  sequenceNbr: string;
  code: string;
  description: string;
  rate: number;
  amount: number;
  delete?: boolean | undefined;
  markedAction?: RequestFormMarkedAction;
  minimumChargeInd: boolean;
}

export class CorrectionsAuditorRemarksRespModel {
  authorityRemarks: string;
  manualRateInd?: boolean;
}

export class CorrectionsCashPaidByShipperRespModel {
  deleteInd?: boolean;
  amount: number;
  description: string;
  sequenceNbr: string;
}

export class CorrectionsCashConsigneeRespModel {
  collectedInd: boolean;
}

export class CorrectionsTimeDateCriticalRespModel {
  shipmentInstId?: number;
  tdcDateTypeCd?: string;
  initialRangeDate: string;
  endRangeDate: string;
  dateSelection: string;
  timeSelection: string;
  initialRangeTime: string;
  endRangeTime: string;
  initialOnDate: string;
  initialAfterTime: string;
  initialBeforeTime: string;
  initialByDate: string;
}

export class CorrectionsSupplementalReferencesRespModel {
  poSupplement?: SupplementalReference;
  snSupplement?: SupplementalReference;
}

export class CorrectionsDetailModel {
  ctsRevenueAmount: number;
  proNbr: string;
  parentProNbr: string;
  childProNbrs: string[];
  pickupDate: string;
  shipmentInstId: number;
  correctionRequestInstId?: number;
  totalChargeAmount: number;
  billingRemarks: string;
  accessorials: CorrectionsAccessorialRespModel[];
  advanceScac: CorrectionsAdvanceScacRespModel;
  auditorComments?: CorrectionsExtraInformationModel;
  auditorRemarks: CorrectionsAuditorRemarksRespModel;
  amcAmount: CorrectionsAmcAmountRespModel;
  beyondScac: CorrectionsBeyondScacRespModel;
  billTo: CorrectionsBilltoRespModel;
  billStatus: CorrectionsBillStatusModel;
  cancelCharges?: CorrectionsCancelChargesRespModel;
  cashCollectedConsignee: CorrectionsCashConsigneeRespModel;
  cashPaidByShipper: CorrectionsCashPaidByShipperRespModel;
  chargeCode: CorrectionsChargeCodeRespModel;
  codAmount: CorrectionsCodAmountRespModel;
  commodities: CorrectionsCommoditiesRespModel[];
  consignee: CorrectionsConsigneeRespModel;
  customInBond: CorrectionsCustomInBondRespModel;
  discountAmount: CorrectionsDiscountAmountRespModel;
  generalInfo: CorrectionsGeneralInfoModel;
  guaranteed: CorrectionsGuaranteedRespModel;
  informationRequestInd?: CorrectionsInformationRequestModel;
  measures: CorrectionsMeasuresRespModel;
  miscellaneous?: CorrectionsMiscellaneousModel;
  pricingBeforeAgreement: CorrectionsPricingAgreementRespModel;
  requestInformation: CorrectionsRequestInformationModel;
  reason?: CorrectionsReasonModel;
  sic: CorrectionsSicRespModel;
  shipper: CorrectionsShipperRespModel;
  shippingRemarks: CorrectionsShippingRemarksRespModel;
  supplementalReferences: CorrectionsSupplementalReferencesRespModel;
  timeDateCritical?: CorrectionsTimeDateCriticalRespModel;
  totalRevenue: CorrectionsTotalRevenueRespModel;
  tariff: CorrectionsTariffRespModel;
  weightLine: CorrectionsWeightLineRespModel
};

export class CorrectionsPricingAgreementRespModel {
  pricingRulesetNbr: number;
  pricingAgreementNbrTxt: string;
  prcAgreementInstId: number;
};

export class CorrectionsCorrectedFormFieldsModel {
  deletedSection?: boolean;
  addedFields?: {[fieldName: string]: string};
  updatedFields?: {[fieldName: string]: string};
};

export type CorrectionsCorrectedFormSectionsModel = {
  [sectionKey in CorrectionsFormEnum]?: CorrectionsCorrectedFormFieldsModel;
};


