import { FormArray, FormGroup } from "@angular/forms";
import { CorrectionsAccessorialsFormEnum } from "@shared/enums/corrections/corrections-form/accessorials/corrections-accessorials-form.enum";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsAccessorialsFormModel } from "@shared/models/corrections/corrections-form/accessorials/corrections-accessorials.model";

export abstract class CorrectionsAccessorialsGetForm {
  static getForm = (form: FormGroup): FormArray => {
    return form.controls[CorrectionsFormEnum.accessorials] as FormArray;
  }

  static getLastFormGroupFromArray = (formArray: FormArray): FormGroup => {
    return formArray.controls[formArray.length - 1] as FormGroup;
  };

  static getFormGroupFromArray = (formArray: FormArray): FormGroup[] => {
    return formArray.controls?.map((form: any) => form as FormGroup);
  };

  static getFormValues = (form: FormGroup): CorrectionsAccessorialsFormModel => {
    const sequenceNbr = form.controls[CorrectionsAccessorialsFormEnum.sequenceNbr];
    const id = form.controls[CorrectionsAccessorialsFormEnum.id];
    const deleteAccessorial = form.controls[CorrectionsAccessorialsFormEnum.delete];
    const code = form.controls[CorrectionsAccessorialsFormEnum.code];
    const description = form.controls[CorrectionsAccessorialsFormEnum.description];
    const rate = form.controls[CorrectionsAccessorialsFormEnum.rate];
    const amount = form.controls[CorrectionsAccessorialsFormEnum.amount];
    const markedAction = form.controls[CorrectionsAccessorialsFormEnum.markedAction];
    const minimumChargeInd = form.controls[CorrectionsAccessorialsFormEnum.minimumChargeInd];

    return {
      sequenceNbr,
      id,
      delete: deleteAccessorial,
      code,
      description,
      rate,
      amount,
      markedAction,
      minimumChargeInd
    };
  };
}
