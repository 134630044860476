export enum CorrectionsAuditorPendingTitleEnum {
  title = 'Pending Corrections For',
}

export enum CorrectionsAuditorSummaryTitleEnum {
  title = 'Request Summary',
  totals = 'Totals',
}

export enum CorrectionsAuditorSummaryTooltipEnum {
  gridDownload = 'Download',
}

export enum CorrectionsAuditorPendingGridFieldEnum {
  correctionRequestInstId = 'correctionRequestInstId',
  requestId = 'requestId',
  proNbr = 'proNbr',
  updateDelivery = 'updateDelivery',
  lastUpdated = 'lastUpdated',
  dateSubmitted = 'dateSubmitted',
  queueName = 'queueName',
  auditorName = 'auditorName',
  requesterName = 'requesterName',
  reasonDescription = 'reasonDescription',
  status = 'status',
}

export enum CorrectionsAuditorReassignGridFieldEnum {
  correctionRequestInstId = 'correctionRequestInstId',
  updateDelivery = 'updateDelivery',
  requestDateTime = 'requestDateTime',
  proNbr = 'proNbr',
  queueName = 'queueName',
  requester = 'requester',
  reason = 'reason',
  status = 'status',
}

export enum CorrectionsAuditorSummaryGridFieldEnum {
  queueName = 'queueName',
  oldestCorrection = 'oldestCorrection',
  overFortyEightHours = 'overFortyEightHours',
  fortyEightHours = 'fortyEightHours',
  twentyFourHours = 'twentyFourHours',
  today = 'today',
}

export enum CorrectionsAuditorReassignIdsEnum {
  reassignButton = 'auditorReassignBtn',
  queueList = 'auditorReassignQueueList',
}

export enum CorrectionsAuditorReassignLabelsEnum {
  reassignButton = 'Reassign Pending Requests',
  reassignLabel = 'Reassign Corrections Queue to',
}

export enum CorrectionsAuditorReassignFormEnum {
  queue = 'queue'
};

export enum CorrectionsAuditorSummaryFileNameEnum {
  grid = 'Corrections - Request Summary',
}

export enum CorrectionsAuditorQueueEnum {
  inspectionQueueName = 'CONTENT'
};
