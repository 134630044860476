import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { CorrectionsDetailModel } from '@shared/models/corrections/corrections-detail.model';
import { CorrectionsFormCreateRequestResponseModel, CorrectionsFormValueModel, CorrectionsTdcFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { CorrectionsTariffFormValuesModel } from '@shared/models/corrections/corrections-form/tariff/corrections-tariff.model';
import { TransformCommonDataFieldsService } from '@shared/services/application-utils/transforms/common-data-fields/transform-common-data-fields.service';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { EmailAddress, UpsertCorrectionRequestResp } from '@xpo-ltl-2.0/sdk-billcorrection';
import { RatingLockTariffService } from '@xpo-ltl/rating';
import { AdvanceBeyondCarrier, AdvanceBeyondTypeCd, CorrectionActionTypeCd, MiscLineItem, MiscLineItemCd } from '@xpo-ltl/sdk-common';
import { take } from 'rxjs/operators';

@Injectable()
export class CorrectionsCreateRequestCommonMapperService {

  constructor(
    private correctionsHandler: CorrectionsHandler,
    private transformCommonDataFieldsService: TransformCommonDataFieldsService,
    private ratingLockTariffService: RatingLockTariffService,
  ) { }

  getActionTypeCd(requestActionTypeCd: RequestActionTypeCd): CorrectionActionTypeCd {
    switch (requestActionTypeCd) {
      case RequestActionTypeCd.approved:
        return CorrectionActionTypeCd.APPROVED;
      case RequestActionTypeCd.hold:
        return CorrectionActionTypeCd.HOLD;
      case RequestActionTypeCd.moreInfo:
        return CorrectionActionTypeCd.MORE_INFO;
      case RequestActionTypeCd.notApproved:
        return CorrectionActionTypeCd.NOT_APPROVED;
      case RequestActionTypeCd.deEscalate:
        return CorrectionActionTypeCd.DE_ESCALATE;
      case RequestActionTypeCd.reSubmit:
        return CorrectionActionTypeCd.SUBMIT;

      default:
        return CorrectionActionTypeCd.SUBMIT;
    }
  }

  getCorrectedDetail(): CorrectionsDetailModel | undefined {
    let correctionsDetail: CorrectionsDetailModel | undefined;

    this.correctionsHandler.getCorrectionsCorrectedDetail$.pipe(
      take(1)
    ).subscribe(
      corrDetail => correctionsDetail = corrDetail
    );

    return correctionsDetail;
  }

  getCorrectionRequestInstId(requestActionTypeCd: RequestActionTypeCd): number | undefined {
    if (this.isMainAction(requestActionTypeCd)) return;

    const correctedDetail = this.getCorrectedDetail();
    return correctedDetail?.correctionRequestInstId;
  }

  getResponse(apiResponse: UpsertCorrectionRequestResp): CorrectionsFormCreateRequestResponseModel {
    const response = new CorrectionsFormCreateRequestResponseModel();
    response.proNbr = apiResponse.correctionRequest.proNbr;

    return response;
  }

  getEmailAddresses(correctionsForm: CorrectionsFormValueModel | CorrectionsTdcFormValueModel): EmailAddress[] {
    const requestInfo = correctionsForm.requestInfo;
    const emails = requestInfo.sendCopyToMyEmail
      ? [requestInfo.loggedUserEmail, ...correctionsForm.requestInfo.ccEmailTo]
      : [...correctionsForm.requestInfo.ccEmailTo];

    return emails.map(emailAddress => ({
        ...new EmailAddress(),
        emailAddress
      })
    );
  }

  getBalanceDueInd(correctionsForm: CorrectionsFormValueModel): boolean {
    const balanceDueInd = correctionsForm.balanceDueInd?.balanceDueInd ?? false;

    return balanceDueInd;
  }

  getPrepaidLine(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if (correctionsForm.chargeCode.ppdAmount || correctionsForm.chargeCode.ppdText) {
      const amount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.chargeCode.ppdAmount) as number;

      return {
        ...new MiscLineItem(),
        lineTypeCd: MiscLineItemCd.PART_PPD_LN,
        amount,
        description: correctionsForm.chargeCode.ppdText
      };
    }
    return undefined as any;
  }

  getCollectLine(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if (correctionsForm.chargeCode.colAmount || correctionsForm.chargeCode.colText) {
      const amount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.chargeCode.colAmount) as number;

      return {
        ...new MiscLineItem(),
        lineTypeCd: MiscLineItemCd.PART_COLL_LN,
        amount,
        description: correctionsForm.chargeCode.colText
      }
    }

    return undefined as any;
  }

  getBeyondCarrier(correctionsForm: CorrectionsFormValueModel): AdvanceBeyondCarrier {
    if (correctionsForm.beyondScac.deleteScac) return undefined as any;

    const chargeAmount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.beyondScac?.beyondRevenue) as number;

    if (!correctionsForm.beyondScac.scacCode && chargeAmount === 0) return undefined as any;

    return {
      ...new AdvanceBeyondCarrier(),
      carrierScacCd: correctionsForm.beyondScac?.scacCode || (null as any),
      typeCd: AdvanceBeyondTypeCd.BYD_CARR,
      chargeAmount
    };
  }

  getAdvanceCarrier(correctionsForm: CorrectionsFormValueModel): AdvanceBeyondCarrier {
    if (correctionsForm.advanceScac.deleteScac) return undefined as any;

    const chargeAmount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.advanceScac?.advanceRevenue) as number;
    const carrierPickupDate = correctionsForm.advanceScac?.date ? DateUtils.momentToString(correctionsForm.advanceScac?.date, DateFormat.yearMonthDayFormat) : undefined;

    if (!correctionsForm.advanceScac.scacCode
      && !carrierPickupDate
      && !correctionsForm.advanceScac.proNumber
      && chargeAmount === 0
    ) {
      return undefined as any;
    }

    return {
      ...new AdvanceBeyondCarrier(),
      typeCd: AdvanceBeyondTypeCd.ADV_CARR,
      carrierScacCd: correctionsForm.advanceScac?.scacCode || (null as any),
      carrierPickupDate: carrierPickupDate || (null as any),
      carrierProNbr: correctionsForm.advanceScac?.proNumber || (null as any),
      chargeAmount
    };
  }

  getTariffCodeValue(tariffCode: string, isAutoRate: boolean): string | undefined {
    if (!isAutoRate) return tariffCode;

    if (this.ratingLockTariffService.isLockTariff(tariffCode)) return tariffCode;

    const originalShipment = this.getCorrectedDetail();
    const originalTariffCode = originalShipment?.tariff?.code;
    if (originalTariffCode !== tariffCode) {
      // NOTE: If tariff has changed compared with original tariff it should be sent
      return tariffCode;
    }

    return undefined;
  }

  isMainAction(requestActionTypeCd: RequestActionTypeCd): boolean {
    return requestActionTypeCd === RequestActionTypeCd.submit;
  }
}
