import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TransformCommonDataFieldsService {

  constructor() { }

  getNumberFromTextField(value: number | string | null | undefined): number | null {
    if (typeof value === 'number') return value;
    if (typeof value === 'string' && value) {
      const numberValue = Number(value.replace(/[, $]/g, ''));
      return isNaN(numberValue) ? null : numberValue;
    }
    return 0;
  }

  getValueFromTimeField(timeValue: string): string {
    return timeValue ? `${timeValue}:00` : timeValue;
  }
}
