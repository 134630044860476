import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { AccessorialsHandler } from '@app-store/common-data/accessorials/handler/accessorials.handler';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { CorrectionsAccessorialsGetForm } from '@corrections/corrections-form/shared/form/accessorials/corrections-form-accessorials-get-form-groups.utils';
import { AccessorialErrorMessageEnum, AccessorialErrorTypeEnum } from '@shared/enums/common-data/accessorials.enum';
import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import { CorrectionsAccessorialsFormEnum } from '@shared/enums/corrections/corrections-form/accessorials/corrections-accessorials-form.enum';
import { CorrectionsAccessorialsFormModel } from '@shared/models/corrections/corrections-form/accessorials/corrections-accessorials.model';
import { AccessorialModel } from '@shared/models/shared/common-data/accessorials.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AccessorialValidatorsService {

  constructor(
    private accessorialsHandler: AccessorialsHandler,
    private correctionsHandler: CorrectionsHandler
  ) { }

  duplicateAcCodeValidator(form: FormGroup): ErrorModel[] {
    let errors: ErrorModel[] = [];
    const accFormArray = CorrectionsAccessorialsGetForm.getForm(form);
    const accFormGroups = CorrectionsAccessorialsGetForm.getFormGroupFromArray(accFormArray);

    const accCodes: any = {};
    const duplicatedCodes: any = {};

    for (const acc of accFormGroups) {
      const code = acc.controls[CorrectionsAccessorialsFormEnum.code];
      if(!code.value) continue;

      const codeValue = `${code.value}`.toUpperCase();
      
      if(accCodes[codeValue]) {
        duplicatedCodes[codeValue] = (accCodes[codeValue] | 0) + 1;
      }

      accCodes[codeValue] = codeValue;
    }

    if(Object.keys(duplicatedCodes).length > 0) {
      const duplicatedKeys = Object.keys(duplicatedCodes).join(', ');
      errors.push({ message: `Duplicate AC Codes: ${duplicatedKeys}`});
    }

    return errors;
  }

  isPickupDateValid(
    accessorialsForm: CorrectionsAccessorialsFormModel):  AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors | null> => {
        if (accessorialsForm.delete.value) {
          return of(null);
        }
        
        return combineLatest([
          this.getSelectedAccessorialFromList(control),
          this.getPickupDate()
        ]).pipe(
          switchMap(([selectedAccessorial, pickupDateString]) => {
            if (!selectedAccessorial || !pickupDateString) {
              return of(null);
            }
    
            const pickupDate = DateUtils.getDateFromString(pickupDateString, DateFormat.yearMonthDayFormat);
            const effectiveDate = DateUtils.getDateFromString(selectedAccessorial.effectiveDate, DateFormat.yearMonthDayFormat);
            const expirationDate = DateUtils.getDateFromString(selectedAccessorial.expirationDate, DateFormat.yearMonthDayFormat);
    
            if (pickupDate < effectiveDate || pickupDate > expirationDate) {
              return of({
                errorMessage: AccessorialErrorMessageEnum.pickupDate,
                typeError: AccessorialErrorTypeEnum.pickupDate
              });
            }
            return of(null);
          })
        );
      };
  }

  getPickupDate(): Observable<string | undefined>{
    return this.correctionsHandler.getPickupDate$.pipe(
      take(1)
    );
  }

  getSelectedAccessorialFromList(accCode: AbstractControl): Observable<AccessorialModel | undefined> {
    return this.accessorialsHandler.getAccessorials$.pipe(
      take(1),
      map(accessorials => accessorials.find(acc => acc.code?.toUpperCase() === accCode.value))
    );
  }
}
