import { Injectable } from '@angular/core';
import { DateFormat } from '@shared/enums/common-data/date-format.enum';
import { CorrectionsCashPaidModel } from '@shared/models/corrections/corrections-form/cash-paid/corrections-cash-paid.model';
import { CorrectionsCodAmountModel } from '@shared/models/corrections/corrections-form/cod-amount/corrections-cod-amount.model';
import { CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { TransformCommonDataFieldsService } from '@shared/services/application-utils/transforms/common-data-fields/transform-common-data-fields.service';
import { DateUtils } from '@shared/utils/date/date-utils.service';
import { GetAutoRatesPreviewQuery, GetManualRatesPreviewRqst } from '@xpo-ltl-2.0/sdk-billcorrection';
import {
  AccessorialService,
  AsMatchedParty, ChargeToCd, MatchedPartyTypeCd, MiscLineItem, MiscLineItemCd
} from '@xpo-ltl/sdk-common';
import { CorrectionsCreateRequestCommonMapperService } from '../../create-request/common/corrections-create-request-common-mapper.service';
import { CorrectionsPreviewRatesCommonRequestMapperService } from '../common/corrections-preview-rates-request-common-mapper.service';

@Injectable()
export class CorrectionsManualRatesRequestMapperService {
  constructor(
    private transformCommonDataFieldsService: TransformCommonDataFieldsService,
    private correctionsPreviewRatesCommonRequestMapperService: CorrectionsPreviewRatesCommonRequestMapperService,
    private correctionsCreateRequestCommonMapperService: CorrectionsCreateRequestCommonMapperService,
  ) {
  }

  getManualRateRequest(correctionsForm: CorrectionsFormValueModel): GetManualRatesPreviewRqst {
    const request = new GetManualRatesPreviewRqst();
    
    request.shipper = this.getShipper(correctionsForm);
    request.accessorialServices = this.getAccesorials(correctionsForm);
    request.billTo = this.getBillTo(correctionsForm);
    request.commodities = this.correctionsPreviewRatesCommonRequestMapperService.getCommodities(correctionsForm);
    request.consignee = this.getConsignee(correctionsForm);
    request.miscLineItems = this.getMiscLineItems(correctionsForm);
    request.originTerminalSicCd = correctionsForm.sic.origin;
    request.destinationTerminalSicCd = correctionsForm.sic.destination;
    request.advanceCarrier = this.correctionsCreateRequestCommonMapperService.getAdvanceCarrier(correctionsForm);
    request.beyondCarrier = this.correctionsCreateRequestCommonMapperService.getBeyondCarrier(correctionsForm);
    request.tariffEffectiveDate = DateUtils.momentToString(correctionsForm.tariff.effectiveDate, DateFormat.yearMonthDayFormat);
    request.overrideRatingTariff = correctionsForm.tariff.code;
    request.chargeToCd = correctionsForm.chargeCode.chargeCode;
    return request;
  }

  getQueryParams(proNbr: string, shipInstId: number, correctionsForm: CorrectionsFormValueModel): GetAutoRatesPreviewQuery {
    const query = new GetAutoRatesPreviewQuery();
    query.pickupDate = correctionsForm.requestInfo.pickupDate;
    query.proNbr = proNbr;
    query.shipmentInstId = shipInstId;
    return query;
  }

  private getAccesorials(correctionsForm: CorrectionsFormValueModel): AccessorialService[] {
    const accesorial: AccessorialService[] = [];
    correctionsForm.accessorials.forEach(value => {
      if (value.delete) return;
      const acc = {...new AccessorialService()};
      acc.amount = this.transformCommonDataFieldsService.getNumberFromTextField(value.amount) as number;
      acc.description = value.description;
      acc.sequenceNbr = value.sequenceNbr as string ?? undefined;
      acc.accessorialCd = value.code;
      acc.tariffsRate = this.transformCommonDataFieldsService.getNumberFromTextField(value.rate) as number;
      accesorial.push(acc);
    });
    return accesorial;
  }

  private getBillTo(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.billto || correctionsForm.billto.deleteBillTo) {
      return undefined as any;
    }

    const billTo ={ ...new AsMatchedParty() };
    billTo.asMatchedMadCd = correctionsForm.billto.madCode || (null as any);
    billTo.cisCustNbr = correctionsForm.billto.customerNumber != null ? (billTo.asMatchedMadCd == null ? 0 : correctionsForm.billto.customerNumber) : (null as any);
    billTo.name1 = correctionsForm.billto.nameOne || (null as any);
    billTo.name2 = correctionsForm.billto.nameTwo || (null as any);
    billTo.address = correctionsForm.billto.address || (null as any);
    billTo.city = correctionsForm.billto.city || (null as any);
    billTo.stateCd = correctionsForm.billto.state || (null as any);
    billTo.countryCd = correctionsForm.billto.country || (null as any);
    billTo.zip6 = correctionsForm.billto.zipCode || (null as any);
    billTo.zip4RestUs = correctionsForm.billto.zipCodeComplementation || (null as any);
    billTo.typeCd = correctionsForm.chargeCode?.chargeCode === ChargeToCd.COLL ? MatchedPartyTypeCd.BILL_TO_INB : MatchedPartyTypeCd.BILL_TO_OTB
    return billTo;
  }

  private getConsignee(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.consignee) return undefined as any;
    const consignee = {...new AsMatchedParty()};

    consignee.asMatchedMadCd = correctionsForm.consignee.madCode || (null as any);
    consignee.cisCustNbr = correctionsForm.consignee.customerNumber != null ? (consignee.asMatchedMadCd == null ? 0 : correctionsForm.consignee.customerNumber) : (null as any);
    consignee.name1 = correctionsForm.consignee.nameOne || (null as any);
    consignee.name2 = correctionsForm.consignee.nameTwo || (null as any);
    consignee.address = correctionsForm.consignee.address || (null as any);
    consignee.city = correctionsForm.consignee.city || (null as any);
    consignee.stateCd = correctionsForm.consignee.state || (null as any);
    consignee.countryCd = correctionsForm.consignee.country || (null as any);
    consignee.zip6 = correctionsForm.consignee.zipCode || (null as any);
    consignee.zip4RestUs = correctionsForm.consignee.zipCodeComplementation || (null as any);
    consignee.typeCd = MatchedPartyTypeCd.CONS;
    consignee.creditStatusCd = correctionsForm.consignee.creditStatus || (null as any);
    return consignee;
  }

  private getShipper(correctionsForm: CorrectionsFormValueModel): AsMatchedParty {
    if (!correctionsForm.shipper) return undefined as any;
    const shipper = {...new AsMatchedParty()};
    shipper.asMatchedMadCd = correctionsForm.shipper.madCode || (null as any);
    shipper.cisCustNbr = correctionsForm.shipper.customerNumber != null ? (shipper.asMatchedMadCd == null ? 0 : correctionsForm.shipper.customerNumber) : (null as any);
    shipper.name1 = correctionsForm.shipper.nameOne || (null as any);
    shipper.name2 = correctionsForm.shipper.nameTwo || (null as any);
    shipper.address = correctionsForm.shipper.address || (null as any);
    shipper.city = correctionsForm.shipper.city || (null as any);
    shipper.stateCd = correctionsForm.shipper.state || (null as any);
    shipper.countryCd = correctionsForm.shipper.country || (null as any);
    shipper.zip6 = correctionsForm.shipper.zipCode || (null as any);
    shipper.zip4RestUs = correctionsForm.shipper.zipCodeComplementation || (null as any);
    shipper.typeCd = MatchedPartyTypeCd.SHPR;
    shipper.creditStatusCd = correctionsForm.shipper.creditStatus || (null as any);
    return shipper;
  }

  private getDiscounts(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if ((!correctionsForm.discountAmount.amount && !correctionsForm.discountAmount.percentage) || correctionsForm.discountAmount.delete) return undefined as any;
    const discount = new MiscLineItem;
    discount.amount = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.discountAmount.amount) as number;
    discount.prepaidPercentage = this.transformCommonDataFieldsService.getNumberFromTextField(correctionsForm.discountAmount.percentage) as number;
    discount.description = correctionsForm.discountAmount.description;
    discount.lineTypeCd = MiscLineItemCd.DISC_LN;
    discount.sequenceNbr = correctionsForm.discountAmount.sequenceNbr ?? undefined as any;
    return discount;
  }

  private getMiscLineItems(correctionsForm: CorrectionsFormValueModel): MiscLineItem[] {
    const miscellaneous: MiscLineItem[] = [];
    miscellaneous.push(
      {
        ...this.correctionsPreviewRatesCommonRequestMapperService.getAsDeficitWeight(correctionsForm)
      },
      {
        ...this.getDiscounts(correctionsForm)
      },
      {
        ...this.getCashPaidByShipper(correctionsForm.cashPaid)
      },
      {
        ...this.getCodAmount(correctionsForm.codAmount)
      },
      {
        ...this.correctionsCreateRequestCommonMapperService.getPrepaidLine(correctionsForm)
      },
      {
        ...this.correctionsCreateRequestCommonMapperService.getCollectLine(correctionsForm)
      }
    );
    let filteredArray = miscellaneous.filter(misc => {
      return misc.lineTypeCd !== undefined
    });
    return filteredArray;
  }

  private getCodAmount(codAmount: CorrectionsCodAmountModel): MiscLineItem {
    if ((!codAmount.description && !codAmount.amount) || codAmount.delete) return new MiscLineItem();
    return {
      ...new MiscLineItem,
      lineTypeCd: MiscLineItemCd.COD_AMT,
      amount: this.transformCommonDataFieldsService.getNumberFromTextField(codAmount.amount) as number,
      description: codAmount.description,
      sequenceNbr: codAmount.sequenceNbr
    };
  }

  private getCashPaidByShipper(cashPaid: CorrectionsCashPaidModel): MiscLineItem {
    if ((!cashPaid.description && !cashPaid.amount) || cashPaid.delete) return new MiscLineItem();
    return {
      ...new MiscLineItem,
      lineTypeCd: MiscLineItemCd.CASH_PPD_LN,
      amount: this.transformCommonDataFieldsService.getNumberFromTextField(cashPaid.amount) as number,
      description: cashPaid.description,
      sequenceNbr: cashPaid.sequenceNbr
    }
  }
}
