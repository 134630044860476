import { FormGroup } from "@angular/forms";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsDiscountAmountFormEnum } from "@shared/enums/corrections/corrections-form/discount-amount/corrections-discount-amount.enum";
import { CorrectionsDiscountAmountFormModel } from "@shared/models/corrections/corrections-form/discount-amount/corrections-discount-amount.model";

export abstract class CorrectionsDiscountAmountGetForm {
    static getForm = (form: FormGroup): FormGroup => {
        return form.controls[CorrectionsFormEnum.discountAmount] as FormGroup;
    }

    static getFormValues = (form: FormGroup): CorrectionsDiscountAmountFormModel => {
        const deleteDiscount = form.controls[CorrectionsDiscountAmountFormEnum.delete];
        const percentage = form.controls[CorrectionsDiscountAmountFormEnum.percentage];
        const description = form.controls[CorrectionsDiscountAmountFormEnum.description];
        const sequenceNbr = form.controls[CorrectionsDiscountAmountFormEnum.sequenceNbr];
        const amount = form.controls[CorrectionsDiscountAmountFormEnum.amount];

        return {
            delete: deleteDiscount,
            sequenceNbr,
            percentage,
            description,
            amount
        };
    };
}
