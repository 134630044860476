import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CorrectionsFormAuditorRemarksGetForm } from '@corrections/corrections-form/shared/form/auditor-remarks/corrections-form-auditor-remarks-get-form-groups.utils';
import { CorrectionsMessagesValue } from '@shared/data-values/corrections/messages.data';
import { ErrorModel } from '@shared/models/shared/error.model';

@Injectable()
export class AuditorRemarksValidatorsService {

  constructor() { }

  auditorCommentsRequired(form: FormGroup): ErrorModel[] {
    const errors: ErrorModel[] = [];
    const remarksForm = CorrectionsFormAuditorRemarksGetForm.getForm(form);
    const { auditorComments } = CorrectionsFormAuditorRemarksGetForm.getFormValues(remarksForm);
    if (!auditorComments.value) {
      const auditorError: ErrorModel = { message: CorrectionsMessagesValue.auditorComments.empty };
      errors.push(auditorError);
    }
    return errors;
  }
}
