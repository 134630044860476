import { CommonModule, DatePipe, LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SideNavReduxModule } from '@app-store/common-data/side-nav/side-nav-redux.module';
import { CorrectionsDetailDataService } from '@corrections/shared/services/corrections-detail-data/corrections-detail-data.service';
import { AccountsReceivableApiService } from '@xpo-ltl-2.0/sdk-accountsreceivable';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoConfirmDialogModule } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XpoDatePickerModule } from '@xpo-ltl/ngx-ltl-core/datepicker';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoSelectModule } from '@xpo-ltl/ngx-ltl-core/select';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoTooltipModule } from '@xpo-ltl/ngx-ltl-core/tooltip';
import { RatingBreadCrumbsModule, RatingCustomerSearchModule, RatingRatePipe, RatingSharedModule, RatingRateInputPipe } from '@xpo-ltl/rating';
import { DataServicesApiService } from '@xpo-ltl/sdk-dataservices';
import { InvoiceApiService } from '@xpo-ltl/sdk-invoice';
import { PricingAgmtMgmtApiService } from '@xpo-ltl/sdk-pricingagmtmgmt';
import { BackBtnComponent } from './components/buttons/back-btn/back-btn.component';
import { OpenInNewWindowBtnComponent } from './components/buttons/open-in-new-window-btn/open-in-new-window-btn.component';
import { CustomerSearchDialogComponent } from './components/dialogs/customer-search-dialog/customer-search-dialog.component';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { CorrectionsFooterComponent } from './components/footers/corrections-footer/corrections-footer.component';
import { MainFooterComponent } from './components/footers/main-footer/main-footer.component';
import { DatePickerFormComponent } from './components/form-fields/date-picker-form/date-picker-form.component';
import { YesNoRadioButtonComponent } from './components/form-fields/radio-buttons/yes-no-radio-button/yes-no-radio-button.component';
import { SelectFieldYesNoComponent } from './components/form-fields/select-fields/select-field-yes-no/select-field-yes-no.component';
import { TextAreaComponent } from './components/form-fields/text-area/text-area.component';
import { TextSubTitleFormComponent } from './components/form-fields/text-sub-title-form/text-sub-title-form.component';
import { TimePickerFormComponent } from './components/form-fields/time-picker-form/time-picker-form.component';
import { SideNavComponent } from './components/navbar/side-nav/side-nav.component';
import { SectionHeadingComponent } from './components/section-heading/section-heading.component';
import { TooltipDialogComponent } from './components/tooltip-dialog/tooltip-dialog.component';
import { InputTextFormatDirective } from './directives/input-text-format/input-text-format.directive';
import { CorrectionsBillDetailsMetadataMapperService } from './mappers/corrections/preview-rates-metadata/bill-details/corrections-bill-details-metadata-mapper.service';
import { PipesModule } from './pipes/pipes.module';
import { FormStylesService } from './services/application-utils/styles/form-styles/form-styles.service';
import { AppSwitcherDataService } from './services/data/app-switcher-data/app-switcher-data.service';
import { LabelModule } from './components/form-fields/label/label.module';
import { AccessorialValidatorsService } from './validators/accessorials-validators.service';
import { ReleaseNotesComponent } from './components/dialogs/release-notes/release-notes.component';
import { MaxAmountValidatorService } from './validators/max-value-validator.service';
import { MinAmountValidatorService } from './validators/min-value-validator.service';
import { CorrectionsFormSubmitValidatorsService } from './validators/form-submit/corrections-form-submit-validators.service';
import { AuditorRemarksValidatorsService } from './validators/auditor-remarks/auditor-remarks-validators.service';
import { WarningSubmitService } from './validators/warning-submit/warning-submit.service';
import { TextDescriptionFormModule } from './components/form-fields/text-description-form/text-description-form-component.module';
import { NegativeChargesValidatorsService } from './validators/negative-charges/negative-charges-validators.service';

@NgModule({
  imports: [
    CommonModule,
    RatingSharedModule,
    FormsModule,
    ReactiveFormsModule,
    RatingCustomerSearchModule,
    RatingBreadCrumbsModule,
    SideNavReduxModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    LabelModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatRadioModule,
    PipesModule,
    XpoIconModule,
    XpoDialogModule,
    XpoFooterModule,
    XpoSelectModule,
    XpoButtonModule,
    XpoTooltipModule,
    XpoDatePickerModule,
    XpoSnackBarModule,
    XpoConfirmDialogModule,
    TextDescriptionFormModule
  ],
  declarations: [
    BackBtnComponent,
    CorrectionsFooterComponent,
    CustomerSearchDialogComponent,
    DatePickerFormComponent,
    InfoDialogComponent,
    ReleaseNotesComponent,
    TimePickerFormComponent,
    MainFooterComponent,
    OpenInNewWindowBtnComponent,
    SectionHeadingComponent,
    SelectFieldYesNoComponent,
    SideNavComponent,
    TextAreaComponent,
    TextSubTitleFormComponent,
    TooltipDialogComponent,
    YesNoRadioButtonComponent,
    InputTextFormatDirective,
  ],
  exports: [
    RatingSharedModule,
    LabelModule,
    BackBtnComponent,
    CorrectionsFooterComponent,
    CustomerSearchDialogComponent,
    DatePickerFormComponent,
    InfoDialogComponent,
    ReleaseNotesComponent,
    TimePickerFormComponent,
    MainFooterComponent,
    OpenInNewWindowBtnComponent,
    SectionHeadingComponent,
    SelectFieldYesNoComponent,
    SideNavComponent,
    TextAreaComponent,
    TextDescriptionFormModule,
    TextSubTitleFormComponent,
    TooltipDialogComponent,
    YesNoRadioButtonComponent,
    InputTextFormatDirective,
    PipesModule
  ],
  providers: [
    RatingRatePipe,
    RatingRateInputPipe,
    TitleCasePipe,
    UpperCasePipe,
    LowerCasePipe,
    AccountsReceivableApiService,
    CorrectionsBillDetailsMetadataMapperService,
    DataServicesApiService,
    InvoiceApiService,
    CorrectionsFormSubmitValidatorsService,
    WarningSubmitService,
    NegativeChargesValidatorsService,
    AccessorialValidatorsService,
    AuditorRemarksValidatorsService,
    MaxAmountValidatorService,
    MinAmountValidatorService,
    PricingAgmtMgmtApiService,
    DatePipe,
    AppSwitcherDataService,
    FormStylesService,
    CorrectionsDetailDataService,
  ]
})
export class SharedModule { }
