import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextDescriptionFormComponent } from './text-description-form.component';
import { TextTransformFormatService } from '@shared/services/application-utils/transforms/text/text-transform-format.service';



@NgModule({
  declarations: [
    TextDescriptionFormComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    TextTransformFormatService
  ],
  exports: [
    TextDescriptionFormComponent
  ]
})
export class TextDescriptionFormModule { }
