import { FormArray, FormGroup } from "@angular/forms";
import { CorrectionsCommoditiesFormEnum } from "@shared/enums/corrections/corrections-form/commodities/corrections-commodities-form.enum";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionCommoditiesFormModel } from "@shared/models/corrections/corrections-form/commodities/corrections-commodities.model";

export abstract class CorrectionsCommoditiesGetForm {
    static getForm = (form: FormGroup): FormArray => {
        return form.controls[CorrectionsFormEnum.commodities] as FormArray;
    }

    static getFormGroupFromArray = (formArray: FormArray): FormGroup[] => {
        return formArray.controls.map((form: any) => form as FormGroup);
    };

    static getLastFormGroupFromArray = (formArray: FormArray): FormGroup => {
        return formArray.controls[formArray.length - 1] as FormGroup;
    };

    static getFormValues = (form: FormGroup): CorrectionCommoditiesFormModel => {
        const id = form.controls[CorrectionsCommoditiesFormEnum.id];
        const sequenceNbr = form.controls[CorrectionsCommoditiesFormEnum.sequenceNbr];
        const deleteCommodity = form.controls[CorrectionsCommoditiesFormEnum.delete];
        const pieces = form.controls[CorrectionsCommoditiesFormEnum.pieces];
        const pkg = form.controls[CorrectionsCommoditiesFormEnum.pkg];
        const hazmat = form.controls[CorrectionsCommoditiesFormEnum.hazmat];
        const description = form.controls[CorrectionsCommoditiesFormEnum.description];
        const nmfc = form.controls[CorrectionsCommoditiesFormEnum.nmfc];
        const classCommodity = form.controls[CorrectionsCommoditiesFormEnum.class];
        const weight = form.controls[CorrectionsCommoditiesFormEnum.weight];
        const rate = form.controls[CorrectionsCommoditiesFormEnum.rate];
        const minimumChargeInd = form.controls[CorrectionsCommoditiesFormEnum.minimumChargeInd];
        const amount = form.controls[CorrectionsCommoditiesFormEnum.amount];
        const markedAction = form.controls[CorrectionsCommoditiesFormEnum.markedAction];

        return {
            id,
            sequenceNbr,
            delete: deleteCommodity,
            pieces,
            pkg,
            hazmat,
            description,
            nmfc,
            class: classCommodity,
            weight,
            rate,
            minimumChargeInd,
            amount,
            markedAction
        };
    };
}
