import { Injectable } from '@angular/core';
import { HandleErrorApiService } from '@shared/utils/apis/error/handle-error-api.service';
import {
  AccountsReceivableApiService,
  ListArPaymentDetailsByProResp,
  ListArPaymentDetailsByShipmentPath,
} from '@xpo-ltl-2.0/sdk-accountsreceivable';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AccountsReceivableDataApiService {
  constructor(
    private accountsReceivableApiService: AccountsReceivableApiService,
    private handleErrorApiService: HandleErrorApiService
  ) {}

  getBillCharges(
    pathParams: ListArPaymentDetailsByShipmentPath,
    dataOptions = { loadingOverlayEnabled: false, toastOnError: false }
  ): Observable<ListArPaymentDetailsByProResp> {
    return this.accountsReceivableApiService.listArPaymentDetailsByShipment(pathParams, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<ListArPaymentDetailsByProResp>(response));
      })
    );
  }
}
