import { Injectable } from '@angular/core';
import { CustomerLocationRespModel } from '@shared/models/corrections/corrections-detail.model';
import { GetCustomerLocationQuery, GetCustomerLocationResp } from '@xpo-ltl-2.0/sdk-customer';

@Injectable()
export class CustomerLocationMapperService {

  constructor() { }

  getRequest(customerNumber: number): GetCustomerLocationQuery {
    const request = new GetCustomerLocationQuery();
    request.customerId = `${customerNumber}`;
    return request;
  }

  getMappedData(apiResponse: GetCustomerLocationResp): CustomerLocationRespModel {
    const response = new CustomerLocationRespModel();
    response.customerMadCodeLocation = apiResponse.customerPickupLocation.customerMadLocation;

    return response;
  }
}
