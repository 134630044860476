import { Injectable } from '@angular/core';
import { CorrectionsRateContentDetailsLabelsEnum } from '@shared/enums/corrections/corrections-common.enum';
import { CorrectionsWeightFormTypeEnum } from '@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum';
import { CorrectionsFormValueModel } from '@shared/models/corrections/corrections-form/corrections-form.model';
import { TransformCommonDataFieldsService } from '@shared/services/application-utils/transforms/common-data-fields/transform-common-data-fields.service';
import { Commodity, MiscLineItem, MiscLineItemCd } from '@xpo-ltl/sdk-common';

@Injectable()
export class CorrectionsPreviewRatesCommonRequestMapperService {
  constructor(
    private transformCommonDataFieldsService: TransformCommonDataFieldsService
  ) {
  }

  getAsDeficitWeight(correctionsForm: CorrectionsFormValueModel): MiscLineItem {
    if (correctionsForm.weight?.deleteWeight || !correctionsForm.weight) return undefined as any;

    const asDeficitWeight = correctionsForm.weight?.optionSelected === CorrectionsWeightFormTypeEnum.asWeight
      ? correctionsForm.weight?.asWeight
      : correctionsForm.weight?.deficitWeight;

    if (!asDeficitWeight?.rate || !asDeficitWeight?.quantity) {
      return undefined as any;
    }

    const quantity = this.transformCommonDataFieldsService.getNumberFromTextField(asDeficitWeight.quantity) as number;
    const amount = this.transformCommonDataFieldsService.getNumberFromTextField(asDeficitWeight.amount) as number;
    const rate = this.transformCommonDataFieldsService.getNumberFromTextField(asDeficitWeight.rate) as number;

    if (!asDeficitWeight.description
      && quantity == null
      && amount == null
      && rate == null
    ) {
      return undefined as any;
    }

    const lineTypeCd = correctionsForm.weight.optionSelected === CorrectionsWeightFormTypeEnum.asWeight
      ? MiscLineItemCd.AS_WGT
      : MiscLineItemCd.DEFICIT_WGT;

    return {
      ...new MiscLineItem(),
      lineTypeCd,
      description: asDeficitWeight.description || (null as any),
      quantity,
      amount,
      tariffsRate: rate,
    };
  }
  
  getCommodities(correctionsForm: CorrectionsFormValueModel): Commodity[] {
    if (!correctionsForm.commodities) return [];
    const commodities: Commodity[] = [];
    correctionsForm.commodities.forEach(commodity => {
      if (commodity.delete) return;
      const rate = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.rate);
      const amount = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.amount);
      const weight = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.weight);
      const pieces = this.transformCommonDataFieldsService.getNumberFromTextField(commodity.pieces);

      const comm = {
        ...new Commodity(),
        amount: amount,
        ratingUnitOfMeasure: CorrectionsRateContentDetailsLabelsEnum.lb,
        classType: commodity.class,
        nmfcItemCd: commodity.nmfc ?? undefined,
        description: commodity.description,
        freezableInd: false,
        packageCd: commodity.pkg,
        piecesCount: pieces,
        weightLbs: weight,
        tariffsRate: rate,
        hazardousMtInd: commodity.hazmat,
        sequenceNbr: commodity.sequenceNbr ?? undefined,
      } as Commodity;
      commodities.push(comm);
    });
    return commodities;
  }
}
