import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class MinAmountValidatorService {

  constructor() { }
  
  minAmountValidator(minAmount: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
  
      const cleanedValue = parseFloat(control.value.toString().replace(/,/g, ''));
  
      return cleanedValue < minAmount ? { min: {min: minAmount} } : null;
    };
  }
}
