import { Injectable } from '@angular/core';
import { CorrectionsHandler } from '@app-store/corrections/handler/corrections.handler';
import { EmailMessagesValue } from '@shared/data-values/corrections/email.data';
import { ApiErrorCodesEnum } from '@shared/enums/common-data/api-error-codes.enum';
import { EmailEnum } from '@shared/enums/common-data/email.enum';
import { UnratedProDialogEnum } from '@shared/enums/common-data/unrated-pro.enum';
import { InfoDialogModel } from '@shared/models/shared/components/dialogs/info-dialog.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { UnratedProModel } from '@shared/models/shared/unrated-pro.model';
import { InfoDialogService } from '@shared/services/dialogs/info-dialog/info-dialog.service';
import { ClientEmailService } from '@shared/services/email/client-email/client-email.service';
import { RouterNavigationCorrectionsService } from '@shared/services/router-navigation/corrections/router-navigation-corrections.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnratedProService {

  constructor(
    private clientEmailService: ClientEmailService,
    private routerNavigationCorrectionsService: RouterNavigationCorrectionsService
  ) { }

  handleUnratedPro(unratedPro: UnratedProModel) {
    console.warn('this is a unrated pro, you dont have access to corrections form')
    this.routerNavigationCorrectionsService.goToBillDetailsContent(unratedPro.proNbr);
  }

  openMailPage(proNbr: string): void {
    const emailLink = this.clientEmailService.getSimpleEmailLink(EmailEnum.lTLnightaudit, EmailMessagesValue.unratedPro.subject(proNbr));
    this.clientEmailService.openEmailLink(emailLink);
  }
}
