import { ValidationRegexPatterns } from "@xpo-ltl/common-services";
import { RegexRules } from "./regex.utils";

export const validateApiTechnicalCodeError = (codeError: string): boolean => {
  return new RegExp(RegexRules.apiTechnicalCodeError).test(codeError);
};

export function boldingNumbers(input: string): string {
  const regex = RegexRules.onlyNumbersAndMoneySign;
  return input.replace(regex, '<b>$&</b>');
}

export function isPstValidDate(dateToCompare: string): boolean {
  const regex = RegexRules.pstValidationDate;
  return regex.test(dateToCompare);
}

//BUSSINESS VALIDATORS

export const validateProNumberLength = (text: string): boolean => {
  return RegexRules.proNumberLength.test(text);
};

export const validateProNumberNineCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.nineDigitPro).test(text);
};

export const validateProNumberTenCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.tenDigitPro).test(text);
};

export const validateProNumberElevenCharacters = (text: string): boolean => {
  return new RegExp(ValidationRegexPatterns.elevenDigitPro).test(text);
};
