import { Injectable } from '@angular/core';
import { WarningSubmitModel } from '@shared/models/corrections/corrections-form/warning-submit.model';
import { Observable, of } from 'rxjs';

@Injectable()
export class WarningSubmitService {

  constructor() { }

  warningValidations(props: WarningSubmitModel): Observable<boolean> {
    return of(true);
  }
  
}
