import { Injectable } from '@angular/core';
import { RequestActionTypeCd } from '@shared/enums/common-data/request-action-type.enum';
import { FormSubmitValidationsModel } from '@shared/models/corrections/corrections-form/form-submit-validations.model';
import { ErrorModel } from '@shared/models/shared/error.model';
import { AccessorialValidatorsService } from '../accessorials-validators.service';
import { AuditorRemarksValidatorsService } from '../auditor-remarks/auditor-remarks-validators.service';
import { NegativeChargesValidatorsService } from '../negative-charges/negative-charges-validators.service';

@Injectable()
export class CorrectionsFormSubmitValidatorsService {

  constructor(
    private accessorialValidatorsService: AccessorialValidatorsService,
    private auditorRemarksValidatorsService: AuditorRemarksValidatorsService,
    private negativeChargesValidatorsService: NegativeChargesValidatorsService,
  ) { }

  submitValidators(params: FormSubmitValidationsModel): ErrorModel[] {
    const acErrors = this.acsValidator(params);
    const auditorCommentsErrors = this.auditorRemarks(params);
    const manualRateIndErrors = this.manualRateInd(params);
    const negativeChargesErrors = this.negativeCharges(params);
    return [
      ...acErrors,
      ...auditorCommentsErrors,
      ...manualRateIndErrors,
      ...negativeChargesErrors
    ];
  }

  private acsValidator(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const duplicatedAcErrors = requestActionType !== RequestActionTypeCd.notApproved ? this.accessorialValidatorsService.duplicateAcCodeValidator(form) : [];
    return duplicatedAcErrors;
  }

  private auditorRemarks(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    const requiredAuditorComments = requestActionType === RequestActionTypeCd.notApproved
      ? this.auditorRemarksValidatorsService.auditorCommentsRequired(form)
      : [];
    return requiredAuditorComments;
  }

  private manualRateInd(params: FormSubmitValidationsModel): ErrorModel[] {
    const { form, requestActionType } = params;

    // NOTE: This feature won't be deployed in prod on 2024-12-13
    // const requiredAuditorComments = requestActionType !== RequestActionTypeCd.notApproved && requestActionType !== RequestActionTypeCd.moreInfo && requestActionType !== RequestActionTypeCd.hold
    //   ? this.auditorRemarksValidatorsService.manualRateInd(form)
    //   : [];
    // return requiredAuditorComments;
    return [];
  }

  private negativeCharges(params: FormSubmitValidationsModel): ErrorModel[] {
    return this.negativeChargesValidatorsService.negativeAmount({ form: params.form, requestActionType: params.requestActionType }) || [];
  }
}
