import { Injectable } from '@angular/core';
import { CorrectionsHistoryDetailModel } from '@shared/models/corrections/bill-details/corrections-history/history-detail/corrections-history-detail.model';
import { CorrectedShipmentRequest } from '@shared/models/shared/apis/correctected-shipment-request-api.model';
import { HandleErrorApiService } from '@shared/utils/apis/error/handle-error-api.service';
import {
  BillCorrectionApiService,
  DeleteCorrectionRequestsQuery,
  DeleteCorrectionRequestsResp,
  GetAutoRatesPreviewQuery,
  GetAutoRatesPreviewResp,
  GetAutoRatesPreviewRqst,
  GetCorrectedShipmentResp,
  GetManualRatesPreviewQuery,
  GetManualRatesPreviewResp,
  GetManualRatesPreviewRqst,
  GetShipmentForPotentialCorrectionResp,
  ListCorrectionMetadataQuery,
  ListCorrectionMetadataResp,
  ListCorrectionRequestQueueSummariesResp,
  ListCorrectionRequestsAndHistoryQuery,
  ListCorrectionRequestsAndHistoryResp,
  UpdateCorrectionRequestStatusPath,
  UpdateCorrectionRequestStatusRqst,
  UpdateQueueForCorrectionRequestsResp,
  UpdateQueueForCorrectionRequestsRqst,
  UpsertCorrectionRequestRqst,
  UpsertCorrectionRequestResp,
  UnlockCorrectionRequestsRqst,
  UnlockCorrectionRequestsResp
} from '@xpo-ltl-2.0/sdk-billcorrection';
import { DataOptions } from '@xpo-ltl/data-api';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CorrectionsShipmentApiService {
  constructor(
    private billCorrectionApiService: BillCorrectionApiService,
    private handleErrorApiService: HandleErrorApiService
  ) {}

  getShipment(
    queryParams: ListCorrectionRequestsAndHistoryQuery,
    data: DataOptions = { loadingOverlayEnabled: true, toastOnError: false }
  ): Observable<GetShipmentForPotentialCorrectionResp> {
    return this.billCorrectionApiService.getShipmentForPotentialCorrection(queryParams, data).pipe(
      catchError((response) => {
        console.error(response);
        const errors = this.handleErrorApiService.handleError<GetShipmentForPotentialCorrectionResp>(response);
        return throwError(errors);
      })
    );
  }

  getCorrectedShipment(request: CorrectedShipmentRequest): Observable<GetCorrectedShipmentResp> {
    return this.billCorrectionApiService.getCorrectedShipment(request.pathParams, request.queryParams).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<GetCorrectedShipmentResp>(response));
      })
    );
  }

  // TO-DO Call to the api

  getHistoryDetail(queryParams: any): Observable<CorrectionsHistoryDetailModel> {
    return of(<any>{});
  }

  getAutoRatePreview(
    queryParams: GetAutoRatesPreviewQuery,
    request: GetAutoRatesPreviewRqst,
    dataOptions: DataOptions = { toastOnError: false }
  ): Observable<GetAutoRatesPreviewResp> {
    return this.billCorrectionApiService.getAutoRatesPreview(request, queryParams, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<GetAutoRatesPreviewResp>(response));
      })
    );
  }

  getManualRatePreview(
    queryParams: GetManualRatesPreviewQuery,
    request: GetManualRatesPreviewRqst,
    dataOptions: DataOptions = { toastOnError: false }
  ): Observable<GetManualRatesPreviewResp> {
    return this.billCorrectionApiService.getManualRatesPreview(request, queryParams, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<GetManualRatesPreviewResp>(response));
      })
    );
  }

  getRequestAndHistory(
    queryParams: ListCorrectionRequestsAndHistoryQuery
  ): Observable<ListCorrectionRequestsAndHistoryResp> {
    return this.billCorrectionApiService.listCorrectionRequestsAndHistory(queryParams).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<GetShipmentForPotentialCorrectionResp>(response));
      })
    );
  }

  getRequestQueueSummaries(dataOptions?: DataOptions): Observable<ListCorrectionRequestQueueSummariesResp> {
    return this.billCorrectionApiService.listCorrectionRequestQueueSummaries(dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<ListCorrectionRequestQueueSummariesResp>(response));
      })
    );
  }

  getListCorrectionsMetaData(
    queryParams: ListCorrectionMetadataQuery,
    dataOptions?: DataOptions
  ): Observable<ListCorrectionMetadataResp> {
    return this.billCorrectionApiService.listCorrectionMetadata(queryParams, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<ListCorrectionMetadataResp>(response));
      })
    );
  }

  deleteCorrectionRequests(queryParams: DeleteCorrectionRequestsQuery): Observable<DeleteCorrectionRequestsResp> {
    return this.billCorrectionApiService.deleteCorrectionRequests(queryParams).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<DeleteCorrectionRequestsResp>(response));
      })
    );
  }

  submitRequest(
    request: UpsertCorrectionRequestRqst, 
    dataOptions: DataOptions = { toastOnError: false }
  ): Observable<UpsertCorrectionRequestResp> {
    return this.billCorrectionApiService.upsertCorrectionRequest(request, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<UpsertCorrectionRequestResp>(response));
      })
    );
  }

  updateCorrectionRequestStatus(
    request: UpdateCorrectionRequestStatusRqst,
    pathParams: UpdateCorrectionRequestStatusPath,
    dataOptions: DataOptions = { toastOnError: false }
  ): Observable<void> {
    return this.billCorrectionApiService.updateCorrectionRequestStatus(request, pathParams, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<void>(response));
      })
    );
  }

  unlockCorrectionRequestStatus(
    request: UnlockCorrectionRequestsRqst,
    dataOptions: DataOptions = { toastOnError: false }
  ): Observable<UnlockCorrectionRequestsResp> {
    return this.billCorrectionApiService.unlockCorrectionRequests(request, dataOptions).pipe(
      catchError((response) => {
        console.error(response);
        return throwError(this.handleErrorApiService.handleError<void>(response));
      })
    );
  }

  updateQueueForCorrectionRequests(
    request: UpdateQueueForCorrectionRequestsRqst, 
    dataOptions?: DataOptions 
    ): Observable<UpdateQueueForCorrectionRequestsResp> {
      return this.billCorrectionApiService.updateQueueForCorrectionRequests(request, dataOptions).pipe(
        catchError((response) => {
          console.error(response);
          return throwError(this.handleErrorApiService.handleError<UpdateQueueForCorrectionRequestsResp>(response));
        })
      );
  }
}
