export enum CorrectionsAccessorialsFormEnum {
  sequenceNbr = 'sequenceNbr',
  id = 'id',
  delete = 'delete',
  code = 'code',
  description = 'description',
  rate = 'rate',
  amount = 'amount',
  markedAction = 'markedAction',
  minimumChargeInd = 'minimumChargeInd',
};

export enum CorrectionsAccessorialsFormLabelEnum {
  delete = 'delete',
  code = 'code',
  description = 'description',
  rate = 'rate',
  amount = 'amount',
};

export enum CorrectionsAccessorialsFormIdEnum {
  delete = 'deleteAccessorials',
  code = 'codeAccessorials',
  description = 'descriptionAccessorials',
  rate = 'rateAccessorials',
  amount = 'amountAccessorials',
};

export enum CorrectionsAccessorialsFormTitlesEnum {
  title = 'Accessorials',
};

export enum CorrectionsAccessorialsTooltipTextEnum {
  title = 'Accessorials',
  message = `
    <div>
      If you know the accessorial code, enter it in Code. Then either click
      the Apply AC Descriptions button or enter a description. If you don't
      know the accessorial code, leave Code blank and enter a description.
      After you submit the request, an auditor will determine the appropriate
      AC code based on your description. <br><br>
      <i>
        TIP: Clicking the Apply AC Descriptions button fills the description,
        but not the amount. Generally, the amount can be blank. An amount is required
        for an accessorial that has a flat rate or when you want to override the
        auto-rated amount for the AC code. Enter amounts with a decimal between the
        dollars and the cents. For example: for $12.55, enter 12.55; for $1.00, enter 1 or 1.00.
      </i>
      <br><br>
      <i>
        TIP: When you request a correction for a customer error using reason code
        11, 15, or 17, you should also add the appropriate accessorial. This way,
        you can see the result of the correction on the preview screen. If you don't
        add the accessorial, you won't see it on the preview screen. The system will
        automatically add it after you submit the request.
      </i>
    </div>`
};

export enum CorrectionsAccessorialsFormRulesEnum {
  maxQuantityOfAccessorialsRow = 10,
  maxAmountValue = 99999.99,
  minAmountValue = -99999.99,
  maxDescriptionLength = 446, // this was requested by Saravana, is the 450 allowed minus the 3 of the code and a space
  maxRateLenght = 11,
  maxCodeLenght = 3
}

export enum CorrectionsAccessorialsFormErrorMessagesEnum {
  invalidNumberFormat = `Only numbers with 2 decimals are allowed`,
  negativeValue = 'Negative values are not allowed for this accessorial.'
}

