import { FormGroup } from "@angular/forms";
import { CorrectionsFormEnum } from "@shared/enums/corrections/corrections-form/corrections-form.enum";
import { CorrectionsAsWeightFormEnum, CorrectionsDeficitWeightFormEnum, CorrectionsWeightFormEnum, CorrectionsWeightFormTypeEnum } from "@shared/enums/corrections/corrections-form/weight/corrections-weight-form.enum";
import { CorrectionsAsWeightTypeFormModel, CorrectionsDeficitWeightTypeFormModel, CorrectionsWeightFormModel } from "@shared/models/corrections/corrections-form/weight/corrections-weight.model";
import { MiscLineItemCd } from "@xpo-ltl/sdk-common";

export abstract class CorrectionsWeightGetForm {
  static getForm = (form: FormGroup): FormGroup => {
    return form.controls[CorrectionsFormEnum.weight] as FormGroup;
  }

  static getAsWeightFormControl = (formWeight: FormGroup): FormGroup => {
    return formWeight.controls[CorrectionsWeightFormEnum.asWeight] as FormGroup;
  }

  static getDeficitWeightFormControl = (formWeight: FormGroup): FormGroup => {
    return formWeight.controls[CorrectionsWeightFormEnum.deficitWeight] as FormGroup;
  }

  static getFormValueFromSelectedOptionWeight = (props: { correctionsForm?: FormGroup, wegithForm?: FormGroup }): CorrectionsAsWeightTypeFormModel | CorrectionsDeficitWeightTypeFormModel |undefined => {
    const weightForm = props.correctionsForm ? CorrectionsWeightGetForm.getForm(props.correctionsForm) : props.wegithForm;
    if(!weightForm) return;
    
    const { optionSelected, asWeight, deficitWeight } = CorrectionsWeightGetForm.getFormValues(weightForm);
    let formWeightControls: CorrectionsAsWeightTypeFormModel | CorrectionsDeficitWeightTypeFormModel | undefined;
    
    if (optionSelected?.value === CorrectionsWeightFormTypeEnum.asWeight) {
      formWeightControls = CorrectionsWeightGetForm.getFormValuesAsWeight(asWeight);
    } else if (optionSelected?.value === CorrectionsWeightFormTypeEnum.deficitWeight) {
      formWeightControls = CorrectionsWeightGetForm.getFormValuesDeficitWeight(deficitWeight);
    }

    return formWeightControls;
  };

  static getFormValueFromTypeWeight = (form: FormGroup): CorrectionsAsWeightTypeFormModel | CorrectionsDeficitWeightTypeFormModel => {
    const type = form.controls[CorrectionsAsWeightFormEnum.type] || form.controls[CorrectionsDeficitWeightFormEnum.type];
    return type.value === MiscLineItemCd.AS_WGT
      ? CorrectionsWeightGetForm.getFormValuesAsWeight(form)
      : CorrectionsWeightGetForm.getFormValuesDeficitWeight(form);
  };

  static getFormValuesAsWeight = (form: FormGroup): CorrectionsAsWeightTypeFormModel => {
    const type = form.controls[CorrectionsAsWeightFormEnum.type];
    const description = form.controls[CorrectionsAsWeightFormEnum.description];
    const quantity = form.controls[CorrectionsAsWeightFormEnum.quantity];
    const rate = form.controls[CorrectionsAsWeightFormEnum.rate];
    const amount = form.controls[CorrectionsAsWeightFormEnum.amount];
    const sequenceNbr = form.controls[CorrectionsAsWeightFormEnum.sequenceNbr];
    const minimumChargeInd = form.controls[CorrectionsAsWeightFormEnum.minimumChargeInd];

    return {
      type,
      description,
      quantity,
      rate,
      amount,
      sequenceNbr,
      minimumChargeInd
    };
  };

  static getFormValuesAsWeightFromGeneralForm = (form: FormGroup): CorrectionsAsWeightTypeFormModel => {
    const { asWeight } = CorrectionsWeightGetForm.getFormValues(form);
    return CorrectionsWeightGetForm.getFormValuesAsWeight(asWeight);
  };

  static getFormValuesDeficitWeight = (form: FormGroup): CorrectionsDeficitWeightTypeFormModel => {
    const type = form.controls[CorrectionsDeficitWeightFormEnum.type];
    const description = form.controls[CorrectionsDeficitWeightFormEnum.description];
    const quantity = form.controls[CorrectionsDeficitWeightFormEnum.quantity];
    const rate = form.controls[CorrectionsDeficitWeightFormEnum.rate];
    const amount = form.controls[CorrectionsDeficitWeightFormEnum.amount];
    const sequenceNbr = form.controls[CorrectionsDeficitWeightFormEnum.sequenceNbr];
    const minimumChargeInd = form.controls[CorrectionsDeficitWeightFormEnum.minimumChargeInd];

    return {
      type,
      description,
      quantity,
      rate,
      amount,
      sequenceNbr,
      minimumChargeInd
    };
  };

  static getFormValuesDeficitWeightFromGeneralForm = (form: FormGroup): CorrectionsDeficitWeightTypeFormModel => {
    const { deficitWeight } = CorrectionsWeightGetForm.getFormValues(form);
    return CorrectionsWeightGetForm.getFormValuesDeficitWeight(deficitWeight);
  };

  static getFormValues = (form: FormGroup): CorrectionsWeightFormModel => {
    const optionSelected = form.controls[CorrectionsWeightFormEnum.optionSelected];
    const deleteWeight = form.controls[CorrectionsWeightFormEnum.deleteWeight];
    const asWeight = form.controls[CorrectionsWeightFormEnum.asWeight] as FormGroup;
    const deficitWeight = form.controls[CorrectionsWeightFormEnum.deficitWeight] as FormGroup;

    return {
      optionSelected,
      deleteWeight,
      asWeight,
      deficitWeight
    };
  };
}
