import { MiscLineItemCd } from "@xpo-ltl/sdk-common/lib/enum/misc-line-item-cd";

export enum CorrectionsWeightFormFieldsEnum {
  type = 'type',
  description = 'description',
  quantity = 'quantity',
  rate = 'rate',
  amount = 'amount',
  sequenceNbr = 'sequenceNbr',
  minimumChargeInd = 'minimumChargeInd'
};

export enum CorrectionsAsWeightFormEnum {
    type = 'type',
    description = 'description',
    quantity = 'quantity',
    rate = 'rate',
    amount = 'amount',
    sequenceNbr = 'sequenceNbr',
    minimumChargeInd = 'minimumChargeInd'
};

export enum CorrectionsDeficitWeightFormEnum {
    type = 'type',
    description = 'description',
    quantity = 'quantity',
    rate = 'rate',
    amount = 'amount',
    sequenceNbr = 'sequenceNbr',
    minimumChargeInd = 'minimumChargeInd'
};

export enum CorrectionsWeightFormEnum {
    deleteWeight = 'deleteWeight',
    optionSelected = 'optionSelected',
    asWeight = 'asWeight',
    deficitWeight = 'deficitWeight',
};

export enum CorrectionsWeightFormLabelEnum {
    deleteWeight = 'Delete Weight Adjustments',
    weightType = 'Type',
    description = 'Description',
    quantity = 'Weight',
    rate = 'rate',
    amount = 'amount',
    asWeight = 'AS WEIGHT',
    deficitWeight = 'DEFICIT WEIGHT',
};

export enum CorrectionsDeficitWeightFormIdsEnum {
  deleteWeight = 'asDeficitWeightDelete',
  optionSelected = 'asDeficitWeightOptionSelected',
  asWeightDescription = 'descriptionAsWeight',
  asWeightQuantity = 'quantityAsWeight',
  asWeightRate = 'rateAsWeight',
  asWeightAmount = 'amountAsWeight',
  deficitWeightDescription = 'descriptionDeficitWeight',
  deficitWeightQuantity = 'quantityDeficitWeight',
  deficitWeightRate = 'rateDeficitWeight',
  deficitWeightAmount = 'amountDeficitWeight',
};

export enum CorrectionsWeightFormTitlesEnum {
    title = 'As/ Deficit Weight'
};

export enum CorrectionsWeightFormTypeEnum {
    asWeight = MiscLineItemCd.AS_WGT,
    deficitWeight = MiscLineItemCd.DEFICIT_WGT
};

export enum CorrectionssWeightFormRulesEnum {
    maxWeight = 999999,
    maxAmountValue = 99999.99,
    maxDescriptionLength = 450,
    maxRateLength = 11
};

export enum CorrectionsWeightFormErrorMessagesEnum {
    invalidNumberFormat = `Only numbers with 2 decimals are allowed`,
    bothWeightsFilled = `A bill cannot have As Weight and Def. Weight at the same time`
};

export enum CorrectionsWeightFormErrorTypeEnum {
    bothWeightsFilled = 'bothWeightsFilled',
    required = 'required'
};
